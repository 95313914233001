<script lang="ts" setup>
import jpointGif from '~/assets/animation/jpoint-animation.gif'
import jpointIcon from '~/assets/img/jpoint-animation-preview.png'
import hcMiniLogo from '~/assets/img/logo/hc-mini.svg?raw'
import hcLogo from '~/assets/img/logo/holders-club-logo-dark.svg'
import jpoolLogo from '~/assets/img/logo/jpool-logo-dark.svg'
import phantomIcon from '~/assets/img/phantom-icon.svg?raw'
import solflareIcon from '~/assets/img/solflare-icon.svg?raw'
import { COMMUNITY_LINK, HOLDERS_CLUB_LINK } from '~/config'
import { formatPrice } from '~/utils'
import { Analytics } from '~/utils/analytics'

const gameStore = useGameStore()

const linkedUrl = computed(() => `${HOLDERS_CLUB_LINK}?telegramId=${gameStore.state.gameUserData?.telegramId}`)

const connections = computed(() => {
  return [
    {
      label: 'Direct link',
      icon: hcMiniLogo,
      link: linkedUrl.value,
    },
    {
      label: 'Phantom',
      icon: phantomIcon,
      link: `https://phantom.app/ul/browse/${encodeURIComponent(`${linkedUrl.value}&wallet=Phantom`)}?ref=https%3A%2F%app.jpool.one`,
    },
    {
      label: 'Solflare',
      icon: solflareIcon,
      link: browseDapp(`${linkedUrl.value}&wallet=Solflare`),
    },
  ]
})

const { state: membershipState } = useMembershipStore()

const isPopup = ref(false)

const isMember = computed(() => membershipState.userData)

const userPoints = computed(() => {
  return formatPrice(gameStore.userPoints ?? 0, 2, 2)
})

const platform = computed(() => gameStore.useWebApp?.platform)
const isMobile = computed(() => platform.value === 'ios' || platform.value === 'android')

// const shareLink = computed(() => {
//   return `https://t.me/share?url=https://t.me/holders_club_test_bot/pumpjack_test_app?startapp=invite_${gameStore.state.gameUserData?.id}`
// })

function buildUrl(path: string, params: URLSearchParams) {
  return `solflare://ul/${path}?${params.toString()}`
}

function browseDapp(link: string) {
  const params = new URLSearchParams({
    ref: 'https://solflare.com',
  })
  return buildUrl(`v1/browse/${encodeURIComponent(link)}`, params)
}

function linkedHandler() {
  Analytics.track({
    event: 'Connect Holders Club',
    userData: {
      id: gameStore.state.gameUserData?.id,
      username: gameStore.state.gameUserData?.username,
      telegramId: gameStore.state.gameUserData?.telegramId,
    } })
}

const jpImage = ref(jpointIcon)

onMounted(() => {
  const image = new Image()
  image.src = jpointGif

  image.onload = () => {
    jpImage.value = jpointGif
  }
})
</script>

<template>
  <div class="pumpjack-wallet" :class="{ 'desctop-view': !isMobile }">
    <div class="pumpjack-wallet__top">
      <div class="top-logos">
        <img :src="hcLogo" alt="holders club logo">
        <img :src="jpoolLogo" alt="jpool logo">
      </div>
      <div>
        <game-user-avatar is-avatar />
      </div>
    </div>
    <div class="pumpjack-wallet__jpoints">
      <img class="jpoint" :src="jpImage" alt="jpoint icon">
      <span>
        {{ userPoints }}
      </span>
    </div>

    <div v-if="!isMember" class="pumpjack-wallet__connect">
      <div class="connect-title">
        Sync Your JPoints
      </div>
      <div class="connect-descr">
        Link to the JPool Holders Club to sync your JPoints and unlock new privileges of the Club.
      </div>
      <div class="connect-actions">
        <q-btn v-if="gameStore.isLinked" :loading="gameStore.loadingState.linkedLoading" class="connect-btn" @click="gameStore.checkLinkedUser">
          Check user
        </q-btn>
        <q-btn v-else-if="isMobile" class="connect-btn" @click="linkedHandler">
          <i-app-clip color="#ffcb25" />
          Link to the Club

          <q-popup-edit
            v-model="isPopup" anchor="top middle" self="bottom middle" class="connection-popup"
            :cover="false"
          >
            <a
              v-for="connection in connections" :key="connection.label" v-close-popup target="_blank"
              :href="connection.link" class="connection-popup__item" @click="gameStore.isLinked = true"
            >
              <i v-html="connection.icon" />
              {{ connection.label }}
            </a>
          </q-popup-edit>
        </q-btn>
        <q-btn
          v-else class="connect-btn" target="_blank"
          :href="linkedUrl"
          @click="gameStore.isLinked = true, linkedHandler()"
        >
          <i-app-clip color="#8BA0AA" class="link-icon" />
          Link to the Club
        </q-btn>
        <q-btn
          class="community-btn" target="_blank"
          :href="COMMUNITY_LINK"
        >
          <i-app-community color="#7AD1F9" />
          Community
        </q-btn>
      </div>
    </div>
    <div v-else class="pumpjack-wallet__connect">
      <div class="connect-title">
        You are already linked
      </div>
      <div class="connect-descr">
        The JPoints you've earned in the game will be synced with your Club balance within 24 hours
      </div>
      <div class="connect-actions">
        <q-btn class="connect-btn" target="_blank" :href="HOLDERS_CLUB_LINK">
          Holders Club
        </q-btn>
        <q-btn
          class="community-btn" target="_blank"
          :href="COMMUNITY_LINK"
        >
          <i-app-community color="#7AD1F9" />
          Community
        </q-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .connection-popup {
  padding: 14px 26px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    text-decoration: none;
    font-weight: 400;
    color: $primary;

    &:first-child {
      svg path {
        fill: $secondary;
      }
    }

    i {
      display: flex;
      align-items: center;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
