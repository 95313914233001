import { defineStore } from 'pinia'
import { Notify } from 'quasar'
import { COOLING_DURATION, COOLING_PERCENTAGE, MIN_TOWER_SPEED, RELOAD_EXCHANGE_TIME, TOWER_STOP_PER_SECOND } from '~/config'
import type { ExchangePriceData, GameConfig, GameEvent, GameUserData, GameUserDataWithBonus, GameUserStatistic, LeaderBoard, SellHistory, TowerState } from '~/services'
import { useGameService } from '~/services'
import { getProfilePhoto } from '~/utils/telegram'

// const mockedData = {
//   user: {
//     id: 283169417,
//     first_name: 'Kirill',
//     last_name: '',
//     username: 'God2be',
//     language_code: 'ru',
//     allows_write_to_pm: true,
//   },
//   chat_instance: '-9006708045496816980',
//   chat_type: 'channel',
//   start_param: 'invite_3508b4a6-ab2d-4c4a-90dc-e8dcae8d5b16',
//   auth_date: '1726072638',
//   hash: '562933f207e6b4695fccda51c40807b8624836c31f80dd488496d38090e71dd3',
// }

export const useGameStore = defineStore('game', () => {
  const state = reactive<GameState>({
    loading: true,
    tgUserData: undefined,
    farmGallons: 0,
    savedGallons: 0,
    gameUserData: undefined,
    gameStatistic: [],
    sellHistory: [],
    leaderboardData: undefined,
    exchangePrices: [],
    referrals: [],
    events: [],
    profileAvatar: undefined,
  })

  const loadingState = reactive({
    sellLoading: false,
    avatarLoading: false,
    linkedLoading: false,
  })

  const isLinked = ref(false)

  const { loadUserData } = useMembershipStore()
  const { updateTowerState } = useGame()

  const useWebApp = ref()
  const initDataUnsafe = computed(() => useWebApp.value?.initDataUnsafe)

  const {
    login,
    getEvents,
    getGameConfig,
    getExchangePrices,
    getGameStatistic,
    getReferrals,
    getTowerData,
    getSellHistory,
    getLeaderboard,
    getUserByUsername,
  } = useGameService()

  const gameConfig = ref<GameConfig>()

  const towerState = ref({
    heatLevel: 0,
    lastClickTime: Date.now(),
    speed: MIN_TOWER_SPEED,
    startFarmingAt: 0,
  })

  const gameFail = ref(false)

  async function loadGameUser(telegramData: TelegramUserData) {
    try {
      state.gameUserData = await login(telegramData)
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadConfig() {
    try {
      gameConfig.value = await getGameConfig()
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadStatistic(id: string) {
    try {
      state.gameStatistic = await getGameStatistic(id) ?? []
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadLeaderboard(userId: string) {
    try {
      state.leaderboardData = await getLeaderboard(userId)
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadReferrals(userId: string) {
    try {
      state.referrals = await getReferrals(userId) ?? []
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadExchangePrices() {
    try {
      state.exchangePrices = await getExchangePrices()
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadTowerState(id: string) {
    try {
      const data = await getTowerData(id)
      const preparedData = calculatedTowerData(data)
      towerState.value = preparedData
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadSellHistory(id: string) {
    try {
      const data = await getSellHistory(id) ?? []
      state.sellHistory = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    } catch (err) {
      throw new Error(String(err))
    }
  }

  async function loadEvents() {
    try {
      const data = await getEvents() ?? []
      state.events = data.filter(e => e.status === 'active')
    } catch (err) {
      throw new Error(String(err))
    }
  }

  function calculatedTowerData(data: TowerState): TowerState {
    const dateNow = Date.now()
    const lastClick = data.lastClickTime
    const differenceInSeconds = Math.floor(Math.abs(dateNow - lastClick) / 1000)

    const slowTo = differenceInSeconds * TOWER_STOP_PER_SECOND
    const currentSpeed = data.speed - slowTo
    data.speed = currentSpeed > MIN_TOWER_SPEED ? currentSpeed : MIN_TOWER_SPEED

    const towerCooling = (differenceInSeconds / (COOLING_DURATION / 1000)) * COOLING_PERCENTAGE
    const coolingTo = data.heatLevel - towerCooling
    data.heatLevel = coolingTo > 0 ? coolingTo : 0
    data.lastClickTime = dateNow
    return data
  }

  async function loadAvatar(userId: string) {
    try {
      loadingState.avatarLoading = true
      const photo = await getProfilePhoto(userId)
      if (photo) {
        state.profileAvatar = photo
      }
    } finally {
      loadingState.avatarLoading = false
    }
  }

  async function checkLinkedUser() {
    try {
      loadingState.linkedLoading = true
      state.gameUserData = await getUserByUsername(state.gameUserData!.username)
      if (!state.gameUserData?.userId) {
        Notify.create({
          message: 'Holders club member is not linked',
          type: 'negative',
          position: 'top',
        })
        return
      }
      await loadUserData(state.gameUserData!.userId)
    } catch (err) {
      Notify.create({
        message: String(err),
        type: 'negative',
        position: 'top',
      })
    } finally {
      loadingState.linkedLoading = false
      isLinked.value = false
    }
  }

  const userPoints = computed(() => {
    if (!state.gameUserData) {
      return 0
    }
    const allPoints = Number(state.gameUserData?.points) + Number(state.gameUserData?.referralPoints ?? 0)
    return allPoints.toFixed(2)
  })

  const preparedReferrals = computed(() => {
    if (!state.gameUserData || !state.referrals || state.referrals.length === 0) {
      return []
    }

    return state.referrals.map((ref) => {
      const points = Number(ref.points) * 0.1
      return {
        ...ref,
        points,
      }
    })?.sort((a, b) => (b.points + b.bonusPoints) - (a.points + a.bonusPoints))
  })

  watchDebounced([
    () => towerState.value.startFarmingAt,
    () => state.gameUserData,
  ], async ([s]) => {
    if (!state.gameUserData) {
      return
    }
    if (s === 0) {
      towerState.value.startFarmingAt = Date.now()
      await updateTowerState()
    }
  }, { debounce: 500 })

  watch(() => initDataUnsafe.value, async (data) => {
    if (Object.keys(data).length !== 0) {
      try {
        state.loading = true
        state.tgUserData = data
        await loadGameUser(data)
        if (state.gameUserData?.id) {
          const requests = [
            loadStatistic(state.gameUserData.id),
            loadTowerState(state.gameUserData.id),
            loadSellHistory(state.gameUserData.id),
            loadLeaderboard(state.gameUserData.id),
            loadReferrals(state.gameUserData.id),
          ]
          if (state.gameUserData?.userId) {
            requests.push(loadUserData(state.gameUserData?.userId))
          }

          await Promise.allSettled(requests)
        }
      } catch (err) {
        console.log(err)
        gameFail.value = true
      } finally {
        state.loading = false
      }

      await loadAvatar(state.gameUserData!.telegramId)
    }
  })

  onMounted(async () => {
    if (!import.meta.env.SSR) {
      const body = document.querySelector('body')
      if (body) {
        body.style.overflow = 'hidden'
      }
      // @ts-expect-error...
      if (window.Telegram && window.Telegram.WebApp) {
        // @ts-expect-error...
        window.Telegram.WebApp.expand()
      }

      await initTelegram()

      setInterval(async () => {
        await loadExchangePrices()
      }, RELOAD_EXCHANGE_TIME * 1000)
    }
  })

  async function initTelegram() {
    const { useWebApp: tgWebApp } = await import('vue-tg')
    useWebApp.value = tgWebApp()
  }

  void (async function () {
    try {
      if (import.meta.env.SSR) {
        return
      }
      await Promise.allSettled([
        loadConfig(),
        loadEvents(),
        loadExchangePrices()])
    } catch (err) {
      console.error(err)
      gameFail.value = true
    }
  })()

  return {
    state,
    loadingState,
    towerState,
    gameConfig,
    gameFail,
    userPoints,
    preparedReferrals,

    useWebApp,

    loadGameUser,

    loadSellHistory,
    calculatedTowerData,

    checkLinkedUser,
    isLinked,
  }
})

export type GameState = {
  loading: boolean
  tgUserData?: TelegramUserData
  farmGallons: number
  savedGallons: number
  gameUserData?: GameUserData
  gameStatistic: GameUserStatistic[]
  exchangePrices: ExchangePriceData[]
  sellHistory: SellHistory[]
  leaderboardData?: LeaderBoard
  referrals: GameUserDataWithBonus[]
  profileAvatar?: string
  events: GameEvent[]
}

export type TelegramUserData = {
  user: {
    id: number
    first_name: string
    last_name: string
    username: string
    language_code: string
    allows_write_to_pm: boolean
    photo_url?: string
  }
  chat_instance: string
  chat_type: string
  auth_date: string
  hash: string
}
