import { HOLDERS_CLUB_APY_URL } from '~/config'

async function getUserData(id: string, cluster: string): Promise<IUserData> {
  return fetch(`${HOLDERS_CLUB_APY_URL}/user/${id}?cluster=${cluster}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      return res.json()
    })
    .catch((err) => {
      throw err
    })
}

export function useHoldersClubService() {
  return {
    getUserData,
  }
}

export type IUserData = {
  id: string
  cluster: string
  tier: number
  pointsEarned: number
  pointsClaimed: number
  imageUrl: string
  credentialMint: string
  isVerified: boolean
  isActive: boolean
  createdAt: Date
  referralCode: string
  telegramUsername?: string
  contactInfo: {
    [key: string]: string
  }
}
