import { defineStore } from 'pinia'
import { Notify } from 'quasar'
import type { IUserData } from '~/services/holders-club'
import { useHoldersClubService } from '~/services/holders-club'

export const useMembershipStore = defineStore('credential', () => {
  const state = reactive<MembershipState>({
    userData: undefined,
  })

  const {
    getUserData,
  } = useHoldersClubService()

  async function loadUserData(id: string, cluster = 'mainnet-beta') {
    try {
      state.userData = await getUserData(id, cluster)
    } catch (err) {
      console.log(err)
      Notify.create({
        message: 'Cat`t get a holders club member. Please try again',
        type: 'negative',
        position: 'top',
      })
    }
  }

  return {
    state,
    loadUserData,
  }
})

export type MembershipState = {
  userData: IUserData | undefined
}
